<template>
  <div class="cbox">

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-weight: bolder;">编辑基本信息</span>
      </div>
      <el-form :model="bodyForm" ref="bodyForm" label-width="120px">

        <el-row>
          <el-col :span="4">
            <el-form-item label="封面图片">
              <el-upload action="" :http-request="uploadOss" :on-success="uploadCover" :show-file-list="false" name="image">
                <div slot="tip" class="el-upload__tip">尺寸1:1</div>
                <img v-if="bodyForm.cover !== ''" :src="bodyForm.cover" width="90">
                <el-button v-else size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="商品名称">
              <el-input v-model="bodyForm.goods_name" placeholder="商品名称"></el-input>
            </el-form-item>
            <el-form-item label="商品描述">
              <el-input v-model="bodyForm.describe" placeholder="商品名称"></el-input>
            </el-form-item>
            <el-row>

              <el-col :span="8">
                <el-form-item label="商品类型">
                  <el-select v-model="bodyForm.category_id" placeholder="请选择">
                    <el-option label="请选择" :value="0"></el-option>
                    <el-option v-for="item in GoodTypes" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否热销">
                  <el-select v-model="bodyForm.is_hot" placeholder="请选择">
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                  </el-select>

                </el-form-item>

              </el-col>
              <el-col :span="8">
                <el-form-item label="虚拟售出">
                  <el-input v-model="bodyForm.system_sold" placeholder="请输入数字"></el-input>
                </el-form-item>
                <div style="font-size:10px;padding-left: 50px; transform: translateY(-15px);color:blue">显示假的销售量</div>



              </el-col>


            </el-row>

            <el-row>

              <el-col :span="6">
                <el-form-item label="所需积分">
                  <el-input v-model="bodyForm.now_coin" placeholder="请输入数字"></el-input>
                </el-form-item>

              </el-col>
              <el-col :span="6">
                <el-form-item label="最低积分">
                  <el-input v-model="bodyForm.now_price" placeholder="请输入数字"></el-input>
                  
                </el-form-item>
                <div style="font-size:10px;padding-left: 50px; transform: translateY(-15px);color:blue">不同规则商品兑换的最低积分</div>

              </el-col>
              <el-col :span="6">
                <el-form-item label="商品原价">
                  <el-input v-model="bodyForm.old_price" placeholder="请输入数字"></el-input>
                </el-form-item>
                <div style="font-size:10px;padding-left: 50px; transform: translateY(-15px);color:blue">商品市场价</div>


              </el-col>
              <el-col :span="6">
                <el-form-item label="排序号">
                  <el-input v-model="bodyForm.sort" placeholder="请输入数字"></el-input>
                </el-form-item>

              </el-col>


            </el-row>
           
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div style="height: 10px;"></div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-weight: bolder;">商品轮播图</span>
      </div>
      <div>
        <el-row>
          <el-col :span="4">

            <el-upload action="" :http-request="uploadOss" :on-success="uploadImages" :show-file-list="false" :multiple="true"
              name="image">
              <el-button size="small" type="primary">点击上传</el-button><span style="margin-left: 20px;">尺寸1:1</span>
            </el-upload>

          </el-col>
        </el-row>
        <div style="height: 10px;"></div>
        <el-row>
          <el-col :span="24">
            <div class="images-box" v-for="(item, index) in bodyForm.images" :key="index">
              <img :src="item" width="90">
              <div class="images-del"><i class="el-icon-close images-del-icon" @click="deleteImages(index)"></i></div>
              <div class="imgright" title="移动到下一个" v-show="index<bodyForm.images.length-1" @click="MoveImageTo(index,1)">
                <svg class="icon" style="width: 30px; height: 30px;vertical-align: top;fill: rgb(0,255,0);overflow: hidden;"
                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3331">
                  <path d="M478.747 312.095l-31.672 31.667L614.673 511.35 447.076 678.94l31.672 31.667 187.724-187.732 11.525-11.524-199.25-199.256z m33.284-248.528c-247.31 0-447.813 200.449-447.813 447.764 0 247.328 200.503 447.804 447.813 447.804 247.279 0 447.75-200.476 447.75-447.804 0-247.315-200.472-447.764-447.75-447.764z m-0.005 850.785c-222.583 0-403.03-180.429-403.03-403.013 0-222.588 180.447-402.99 403.03-402.99 222.552 0 402.977 180.402 402.977 402.99 0 222.584-180.425 403.013-402.977 403.013z"
                    p-id="3332"></path>
                </svg>
              </div>
              <div class="imgleft" title="移动到上一个" v-show="index>0" @click="MoveImageTo(index,-1)">
                <svg class="icon" style="width: 30px; height: 30px;vertical-align: top;fill: rgb(0,255,0);overflow: hidden;"
                  viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3331">
                  <path d="M478.747 312.095l-31.672 31.667L614.673 511.35 447.076 678.94l31.672 31.667 187.724-187.732 11.525-11.524-199.25-199.256z m33.284-248.528c-247.31 0-447.813 200.449-447.813 447.764 0 247.328 200.503 447.804 447.813 447.804 247.279 0 447.75-200.476 447.75-447.804 0-247.315-200.472-447.764-447.75-447.764z m-0.005 850.785c-222.583 0-403.03-180.429-403.03-403.013 0-222.588 180.447-402.99 403.03-402.99 222.552 0 402.977 180.402 402.977 402.99 0 222.584-180.425 403.013-402.977 403.013z"
                    p-id="3332"></path>
                </svg>
              </div>
            </div>
          </el-col>
        </el-row>

      </div>
    </el-card>
    <div style="height: 10px;"></div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-weight: bolder;">编辑商品规格、价格与库存</span>
      </div>
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input placeholder="请输入规格分类名称" v-model="bodyForm.spec_title">

            </el-input>
          </el-col>
          <el-col :span="8">
            <el-input placeholder="请输入规格分类名称" v-model="bodyForm.size_title">

            </el-input>
          </el-col>
        </el-row>
        <div v-for="(item,idx) in SpecList" :key="idx" style="border-bottom: 1px solid #FBFCFD;">
          <el-row :gutter="20">
            <el-col :span="8">
              <div style="padding: 20px 0;">
                <el-tag style="cursor: pointer;" :type="item.checked?'danger':''" @close="handleSpecRemove(item)"
                  :closable="true">
                  <span @dblclick="editSpec(item)">{{item.spec_name}}</span>
                </el-tag>
              </div>

            </el-col>
            <el-col :span="16">
              <div style="padding: 20px 0;">
                <el-tag type="success" style="margin-right: 10px;cursor: pointer;" @close="handleSizeRemove(item,size)"
                  :closable="true" :key="i" v-for="(size,i) in item.size">
                  <span @dblclick="editSpec(size)">{{size.size_name}}</span>
                </el-tag>
                <el-button size="small" @click="addSize(item)" icon="el-icon-circle-plus-outline"></el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-button style="margin-top: 10px;" size="small" @click="addSpec" icon="el-icon-circle-plus-outline"></el-button>
          </el-col>
          <el-col :span="16">

          </el-col>
        </el-row>
      </div>
      <div style="padding-top: 20px;">
        <fieldset style="border: 1px solid #f3f4f4; padding: 10px; margin-bottom: 10px; border-radius: 4px;">
          <legend>商品规格</legend>
          <table style="width: 100%;" class="spectable" cellspacing="0">
            <thead>

              <tr>
                <th colspan="3" style="width:50%;text-align: center;">批量修改</th>

                <th style="width:10%">
                  <input type="text" class="tdtxt" v-model="CommenSpec.size_coin" @input="PiLiang('size_coin')" />
                </th>
                <th style="width:10%">
                  <input type="text" class="tdtxt" v-model="CommenSpec.size_price" @input="PiLiang('size_price')" />
                </th>
                <th style="width:10%">
                  <input type="text" class="tdtxt" v-model="CommenSpec.weight" @input="PiLiang('weight')" />
                </th>
                <th style="width:10%">
                  <input type="text" class="tdtxt" v-model="CommenSpec.stock" @input="PiLiang('stock')" />
                </th>
                <th style="width:10%">
                  <input type="text" class="tdtxt" v-model="CommenSpec.code" @input="PiLiang('code')" />
                </th>
                <th style="width:10%">

                  <el-switch active-color="#13ce66" inactive-color="#b9b9b9" v-model="CommenSpec.status" @change="PiLiang('status')">
                  </el-switch>

                </th>

              </tr>

              <tr>
                <th style="width:10%">
                  图
                </th>
                <th style="width:14%">{{bodyForm.spec_title}}</th>
                <th>{{bodyForm.size_title}}</th>
                <th style="width:10%">
                  积分(个)
                </th>
                <th style="width:10%">
                  价格(元)
                </th>
                <th style="width:10%">
                  重量(g)
                </th>
                <th style="width:10%">
                  库存
                </th>
                <th style="width:10%">
                  商品码
                </th>
                <th style="width:10%">
                  启用状态
                </th>

              </tr>

            </thead>

            <tbody id="spectable">
              <tr v-for="(row,rowidx) in ShowSpecList" :key="rowidx">
                <td style="text-align: center; position: relative;" :rowspan="row.first?row.rowspan:1" v-if="row.first">
                  <div style="position: relative;">
                    <img :src="row.spec.image?row.spec.image:'//shanghaibus.oss-cn-shanghai.aliyuncs.com/otherpros/chenxi/_nopic.png'"
                      class="specimage" style="width: 50px; height: 50px;" />
                    <div style="width: 100%; height: 50px; overflow: hidden; position: absolute; top: 0;  left: 0; opacity: 0;">
                      <el-upload action="" :http-request="uploadOss" :on-success="uploadSpecImages" :show-file-list="false"
                        name="image">
                        <el-button size="small" type="primary" @click="SetTempIdx(rowidx)">点击上传</el-button>
                      </el-upload>
                    </div>
                  </div>

                </td>
                <td :rowspan="row.first?row.rowspan:1" v-if="row.first">{{row.spec.spec_name}}</td>
                <td>{{row.size.size_name}}</td>
                <td>
                  <input type="text" class="tdtxt" v-model="row.size.size_coin" />
                </td>
                <td>
                  <input type="text" class="tdtxt" v-model="row.size.size_price" />
                </td>
                <td>
                  <input type="text" class="tdtxt" v-model="row.size.weight" />
                </td>
                <td>
                  <input type="text" class="tdtxt" v-model="row.size.stock" />
                </td>
                <td>
                  <input type="text" class="tdtxt" v-model="row.size.code" />
                </td>

                <td>

                  <el-switch active-color="#13ce66" @change="changeStatus($event, rowidx)" v-model="row.size.status == '0' ? false : true"
                    inactive-color="#b9b9b9">
                  </el-switch>

                </td>

              </tr>
            </tbody>

          </table>
        </fieldset>

      </div>
    </el-card>
    <div style="height: 10px;"></div>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-weight: bolder;">商品详情(上传图片)</span>

      </div>

      <el-row>
        <el-col :span="4">

          <el-upload action="" :http-request="uploadOss" :on-success="uploadDetails" :show-file-list="false" :multiple="true"
            name="image">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>

        </el-col>
      </el-row>

      <div style="width: 450px; height: 650px; margin-top: 10px; border: 1px solid #CCCCCC; overflow: auto;">
        <div v-for="(img,index) in bodyForm.detail" style="position: relative;">
          <img :src="img" style="width: 100%; vertical-align: middle;" />
          <div class="imagecover">
            <div class="images-del2"><i class="el-icon-close images-del-icon" @click="deleteImages2(index)"></i></div>
            <div class="imgright2" title="移动到下一个" v-show="index<bodyForm.detail.length-1" @click="MoveImageTo2(index,1)">
              <svg class="icon" style="width: 30px; height: 30px;vertical-align: top;fill: rgb(0,255,0);overflow: hidden;"
                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3331">
                <path d="M478.747 312.095l-31.672 31.667L614.673 511.35 447.076 678.94l31.672 31.667 187.724-187.732 11.525-11.524-199.25-199.256z m33.284-248.528c-247.31 0-447.813 200.449-447.813 447.764 0 247.328 200.503 447.804 447.813 447.804 247.279 0 447.75-200.476 447.75-447.804 0-247.315-200.472-447.764-447.75-447.764z m-0.005 850.785c-222.583 0-403.03-180.429-403.03-403.013 0-222.588 180.447-402.99 403.03-402.99 222.552 0 402.977 180.402 402.977 402.99 0 222.584-180.425 403.013-402.977 403.013z"
                  p-id="3332"></path>
              </svg>
            </div>
            <div class="imgleft2" title="移动到上一个" v-show="index>0" @click="MoveImageTo2(index,-1)">
              <svg class="icon" style="width: 30px; height: 30px;vertical-align: top;fill: rgb(0,255,0);overflow: hidden;"
                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3331">
                <path d="M478.747 312.095l-31.672 31.667L614.673 511.35 447.076 678.94l31.672 31.667 187.724-187.732 11.525-11.524-199.25-199.256z m33.284-248.528c-247.31 0-447.813 200.449-447.813 447.764 0 247.328 200.503 447.804 447.813 447.804 247.279 0 447.75-200.476 447.75-447.804 0-247.315-200.472-447.764-447.75-447.764z m-0.005 850.785c-222.583 0-403.03-180.429-403.03-403.013 0-222.588 180.447-402.99 403.03-402.99 222.552 0 402.977 180.402 402.977 402.99 0 222.584-180.425 403.013-402.977 403.013z"
                  p-id="3332"></path>
              </svg>
            </div>

          </div>
        </div>
      </div>

      <div style="height: 10px;"></div>

    </el-card>

    <div style="height: 10px;"></div>
    <div style=" padding:50px; text-align: center;">
      <el-button @click="SaveGood(1)" type="primary">发布商品</el-button>
      <el-button @click="SaveGood(0)">保存草稿</el-button>
    </div>
    <el-dialog :close-on-click-modal="false" title="添加规格" :visible.sync="dialogVisible">

      <el-input placeholder="请输入规格值" v-model="NewClassify">
        <template slot="append">
          <el-button type="primary" @click="addNewClassify">添加</el-button>
        </template>
      </el-input>

    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="编辑规格" :visible.sync="specEditVisible">

      <el-input placeholder="请输入规格值" v-model="SpecEditItemName">
        <template slot="append">
          <el-button type="primary" @click="EditClassify">修改</el-button>
        </template>
      </el-input>

    </el-dialog>
  </div>
</template>
<script>
  //	import UEditor from '../../components/ueditorlv2.vue';

  export default {
    components: {
      //			UEditor

    },
    data() {
      return {
        dialogVisible: false,
        NewClassify: "",
        spectype: "spec", //size
        bodyForm: {
          id: '',
          goods_name: '',
          now_price: '',
          now_coin: '',
          old_price: "",
          cover: '',
          remark: "",
          images: [],
          detail: [],
          category_id: 0,
          is_hot: 0,
          is_new: 0,
          spec_title: "",
          size_title: '',
          status: 1,
          system_sold: "",
          activity: [],
          sort: ''

        },
        SpecList: [],
        GoodTypes: [],
        ShowSpecList: [],
        ActivityList: [],
        ActivityListIdx: [],
        CommenSpec: {
          size_coin: "",
          size_price: "",
          status: true,
          weight: "",
          stock: "",
          code: ""
        },
        specEditVisible: false,
        SpecEditItem: {
          name: ""
        },
        SpecEditItemName: ""

      }
    },

    created() {

      this.bodyForm.id = this.$route.params.id;
      this.getActivity();
      this.initGoodTypes();
      //this.getProduct(this.bodyForm.id)
    },

    methods: {
      getActivity() {

        // this.$http.post("/api/activity_list").then(res => {
        //   this.ActivityList = res.data;
        //   this.ActivityListIdx = res.data.map(e => {
        //     return e.id
        //   })
        //   console.log(this.ActivityListIdx)
        //   if (this.bodyForm.id > 0) { //从缓存里取数据
        //     this.getData();
        //   }
        // })

        if (this.bodyForm.id > 0) { //从缓存里取数据
            this.getData();
          }

      },
      setActivity(a) {
        console.log(a)

      },
      getData() {
        this.$http.post("/api/good_getOne", {
          id: this.bodyForm.id
        }).then(res => {
          let product = res.data;
          this.bodyForm = product;
          try {
            this.bodyForm.detail = JSON.parse(this.bodyForm.detail)
            if (typeof this.bodyForm.detail == 'string') {
              this.bodyForm.detail = [this.bodyForm.detail];
            }
          } catch (e) {
            this.bodyForm.detail = [];
          }
          try {
            this.bodyForm.images = JSON.parse(this.bodyForm.images)
            if (typeof this.bodyForm.images == 'string') {
              this.bodyForm.images = [this.bodyForm.images];
            }
          } catch (e) {
            this.bodyForm.images = [];
          }
          try {
            this.bodyForm.activity = JSON.parse(this.bodyForm.activity)
            if (typeof this.bodyForm.activity == 'string' || typeof this.bodyForm.activity == 'null') {
              this.bodyForm.activity = [];
            }
          } catch (e) {
            this.bodyForm.activity = [];
          }
          if (!this.bodyForm.activity) {
            this.bodyForm.activity = []
          }
          console.log(this.bodyForm)

          this.initProSpec();




        })
      },

      initProSpec() { //整合已有商品的


        console.log(this.bodyForm.specs)
        this.SpecList = this.bodyForm.specs;
        this.InitSpeclist();
      },
      changeStatus($event, inx) {
        console.log(this.ShowSpecList)
        this.ShowSpecList[inx]['size']['status'] = $event ? 1 : 0
        this.updateSepc(this.ShowSpecList[inx]['size'])
      },
      initGoodTypes() {
        this.GoodTypes = [{
          id: 11,
          name: "文具"
        },{
          id: 12,
          name: "玩具"
        },{
          id: 1,
          name: "蔬菜豆制品"
        }, {
          id: 2,
          name: "肉禽蛋"
        }, {
          id: 3,
          name: "水产海鲜"
        }, {
          id: 4,
          name: "水果"
        }, {
          id: 5,
          name: "手工艺品"
        }, {
          id: 6,
          name: "米面粮油"
        }, {
          id: 7,
          name: "非遗"
        }, {
          id: 8,
          name: "酒饮零食"
        }]
      },
      addSpec() {
        this.spectype = "spec";
        this.dialogVisible = true
      },
      addSize(item) {
        for (let item of this.SpecList) {
          item.checked = false;
        }
        item.checked = true;
        this.spectype = "size";
        this.dialogVisible = true
      },
      editSpec(item) {
        this.SpecEditItemName = item.spec_name || item.size_name;
        this.SpecEditItem = item;
        console.log(item)
        this.specEditVisible = true
      },
      EditClassify() {

        if (this.SpecEditItem.goods_spec_id) { //编辑的是size
          this.SpecEditItem.size_name = this.SpecEditItemName;
          this.$http.post("/api/good_createSize", this.SpecEditItem).then(res => {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.specEditVisible = false
          })
        } else {
          this.SpecEditItem.spec_name = this.SpecEditItemName;
          this.$http.post("/api/good_createSpec", this.SpecEditItem).then(res => {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.specEditVisible = false
          })
        }
      },
      addNewClassify() {
        if (this.NewClassify == "") {
          return
        }
        if (this.spectype == "size") {
          this.addNewSize();
        } else {
          for (let item of this.SpecList) {
            item.checked = false;
          }
          this.SpecList.push({
            id: 0,
            spec_name: this.NewClassify,
            goods_id: this.bodyForm.id,
            image: "",
            status: 1,
            checked: true,
            size: []
          })
          this.dialogVisible = false;
          this.NewClassify = "";
          this.InitSpeclist();
        }
      },
      addNewSize() {

        for (let item of this.SpecList) {
          if (item.checked) {
            item.size.push({
              id: 0,
              size_name: this.NewClassify,
              goods_id: this.bodyForm.id,
              size_coin: "",
              size_price: "",
              goods_spec_id: 1,
              checked: true,
              status: 1,
              stock: "",
              code: "",
              weight: ""
            })
            break;
          }
        }

        this.dialogVisible = false;
        this.NewClassify = "";
        console.log(this.SpecList)
        this.InitSpeclist();

      },
      handleSpecRemove(item) {
        this.$confirm('确认删除该规格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          if (item.id > 0) {
            this.$http.post("/api/good_deleteSpec", {
              id: item.id
            }).then(res => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });

              for (let s = 0; s < this.SpecList.length; s++) {
                if (this.SpecList[s].spec_name == item.spec_name) {
                  this.SpecList.splice(s, 1);
                  break;
                }
              }
              this.InitSpeclist();

            })
          } else {
            for (let s = 0; s < this.SpecList.length; s++) {
              if (this.SpecList[s].spec_name == item.spec_name) {
                this.SpecList.splice(s, 1);
                break;
              }
            }
            this.InitSpeclist();
          }

        }).catch(() => {

        });
      },
      handleSizeRemove(item, size) {

        this.$confirm('确认删除该规格?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (size.id > 0) {
            this.$http.post("/api/good_deleteSize", {
              id: size.id
            }).then(res => {

              this.$message({
                type: 'success',
                message: '删除成功!'
              });

              for (let s = 0; s < item.size.length; s++) {
                if (item.size[s].size_name == size.size_name) {
                  item.size.splice(s, 1);
                  break;
                }
              }
              this.InitSpeclist();

            })
          } else {
            for (let s = 0; s < item.size.length; s++) {
              if (item.size[s].size_name == size.size_name) {
                item.size.splice(s, 1);
                break;
              }
            }
            this.InitSpeclist();
          }

        }).catch(() => {

        });
      },
      PiLiang(k) {
        if (k == "size_coin") {
          for (let item of this.SpecList) {
            for (let s of item.size) {
              s.size_coin = this.CommenSpec.size_coin

            }
          }
        } else if (k == "size_price") {
          for (let item of this.SpecList) {
            for (let s of item.size) {
              s.size_price = this.CommenSpec.size_price

            }
          }
        } else if (k == "status") {
          for (let item of this.SpecList) {
            for (let s of item.size) {
              s.status = this.CommenSpec.status

            }
          }
        } else if (k == "weight") {
          for (let item of this.SpecList) {
            for (let s of item.size) {
              s.weight = this.CommenSpec.weight

            }
          }
        } else if (k == "code") {
          for (let item of this.SpecList) {
            for (let s of item.size) {
              s.code = this.CommenSpec.code

            }
          }
        } else if (k == "stock") {
          for (let item of this.SpecList) {
            for (let s of item.size) {
              s.stock = this.CommenSpec.stock

            }
          }
        }
        this.InitSpeclist();
      },
      InitSpeclist() { //计算规格列
        let arr = [];
        for (let item of this.SpecList) {
          if (item.sizes) {
            item.size = item.sizes;
          }
          for (let i = 0; i < item.size.length; i++) {
            if (item.size[i].status == '1' || item.size[i].status === true) {
              item.size[i].status = true
            } else {
              item.size[i].status = false
            }
            let spitem = {
              first: false,
              rowspan: 1,
              spec: item,
              size: item.size[i]
            }
            if (i == 0) {
              spitem.first = true;
              spitem.rowspan = item.size.length
            }
            arr.push(spitem);
          }
        }
        this.ShowSpecList = arr;
      },
      SetTempIdx(rowidx) {
        for (let item of this.ShowSpecList) {
          item.uploadimg = false;
        }
        for (let i = rowidx; i <= rowidx + this.ShowSpecList[rowidx].spec.size.length; i++) {
          this.ShowSpecList[rowidx].uploadimg = true;
        }
      },
      uploadCover(e) {
        this.bodyForm.cover = e.src
      },
      uploadImages(e) {
        this.bodyForm.images.push(e.src)
      },
      uploadSpecImages(e) {
        for (let item of this.ShowSpecList) {
          if (item.uploadimg) {
            console.log(item)
            item.spec.image = e.src;
            //this.updateSepc(item.spec)
          }
        }
      },
      updateSepc(item) {
        let data = JSON.parse(JSON.stringify(item));
        console.log("保存状态")
        console.log(data)
        //delete data.size;
        if (item.id > 0) {
          this.$http.post("/api/good_createSize", item).then(res => {

          })
        }

      },
      uploadDetails(e) {
        console.log(e)
        this.bodyForm.detail.push(e.src)
      },
      MoveImageTo(idx, step) {
        let arr1 = []; //前半部分
        let arr2 = []; //后半部分
        let firstok = false; //已经到了索引号
        let one = null; //当前元素
        let two = null; //交换元素
        for (let i = 0; i < this.bodyForm.images.length; i++) {
          if (i == idx || i == (idx + step)) {
            firstok = true;
            if (i == idx) {
              one = this.bodyForm.images[i]
            } else if (i == idx + step) {
              two = this.bodyForm.images[i]
            }
          } else if (!firstok) {
            arr1.push(this.bodyForm.images[i])
          } else {
            arr2.push(this.bodyForm.images[i])
          }
        }
        if (step > 0) {
          arr1.push(two);
          arr1.push(one);
          this.bodyForm.images = arr1.concat(arr2)
        } else {

          arr1.push(one);
          arr1.push(two);
          this.bodyForm.images = arr1.concat(arr2)
        }
      },
      deleteImages(index) {
        this.bodyForm.images.splice(index, 1);
      },
      MoveImageTo2(idx, step) {
        let arr1 = []; //前半部分
        let arr2 = []; //后半部分
        let firstok = false; //已经到了索引号
        let one = null; //当前元素
        let two = null; //交换元素
        for (let i = 0; i < this.bodyForm.detail.length; i++) {
          if (i == idx || i == (idx + step)) {
            firstok = true;
            if (i == idx) {
              one = this.bodyForm.detail[i]
            } else if (i == idx + step) {
              two = this.bodyForm.detail[i]
            }
          } else if (!firstok) {
            arr1.push(this.bodyForm.detail[i])
          } else {
            arr2.push(this.bodyForm.detail[i])
          }
        }
        if (step > 0) {
          arr1.push(two);
          arr1.push(one);
          this.bodyForm.detail = arr1.concat(arr2)
        } else {

          arr1.push(one);
          arr1.push(two);
          this.bodyForm.detail = arr1.concat(arr2)
        }

      },
      deleteImages2(index) {
        this.bodyForm.detail.splice(index, 1);
      },
      SaveGood(status) {
        if (!this.bodyForm.goods_name) {
          this.$notify({
            title: '警告',
            message: '商品名称不能为空',
            type: 'warning'
          });
          return
        }
        if (!this.bodyForm.describe) {
          this.$notify({
            title: '警告',
            message: '商品描述不能为空',
            type: 'warning'
          });
          return
        }

        if (!this.bodyForm.old_price) {
          this.$notify({
            title: '警告',
            message: '商品原价格不能为空',
            type: 'warning'
          });
          return
        }
        if (!this.bodyForm.cover) {
          this.$notify({
            title: '警告',
            message: '请上传商品封面图',
            type: 'warning'
          });
          return
        }
        if (this.bodyForm.category_id === "") {
          this.$notify({
            title: '警告',
            message: '请选择商品分类',
            type: 'warning'
          });
          return
        }

        if (!this.bodyForm.spec_title) {
          this.$notify({
            title: '警告',
            message: '请输入商品规格分类名称',
            type: 'warning'
          });
          return
        }
        if (!this.bodyForm.size_title) {
          this.$notify({
            title: '警告',
            message: '请输入商品规格分类名称',
            type: 'warning'
          });
          return
        }
        if (this.bodyForm.images.length == 0) {
          this.$notify({
            title: '警告',
            message: '请上传商品轮播图',
            type: 'warning'
          });
          return
        }
        if (this.ShowSpecList.length == 0) {
          this.$notify({
            title: '警告',
            message: '商品规格不能为空',
            type: 'warning'
          });
          //return
        } else {
          for (let item of this.ShowSpecList) {
            if (isNaN(item.size.size_coin) || isNaN(item.size.size_coin)) {
              this.$notify({
                title: '警告',
                message: '商品规格价格错误',
                type: 'warning'
              });
              //return
            }
            if (!item.spec.image) {
              this.$notify({
                title: '警告',
                message: '请上传商品规格图片',
                type: 'warning'
              });
              //return
            }
          }
        }

        let good = {
          goods_name: (this.bodyForm.goods_name),
          describe: this.bodyForm.describe,
          now_price: this.bodyForm.now_price,
          now_coin: this.bodyForm.now_coin,

          cover: this.bodyForm.cover,
          images: JSON.stringify(this.bodyForm.images),
          detail: JSON.stringify(this.bodyForm.detail),
          category_id: this.bodyForm.category_id,
          spec_title: this.bodyForm.spec_title,
          size_title: this.bodyForm.size_title,
          activity: JSON.stringify(this.bodyForm.activity),
          system_sold: this.bodyForm.system_sold ? this.bodyForm.system_sold : 0,
          old_price: this.bodyForm.old_price ? this.bodyForm.old_price : 0,
          is_new: this.bodyForm.is_new ? this.bodyForm.is_new : 0,
          is_hot: this.bodyForm.is_hot ? this.bodyForm.is_hot : 0,
          sort: this.bodyForm.sort ? this.bodyForm.sort : 0,
          status: status
        }

        console.log(good)

        // return;

        if (this.bodyForm.id == 0) {
          this.$http.post("/api/good_createItem", good).then(res => {
            if (res.data.status == "fail") {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
              return
            }
            this.bodyForm.id = res.data.id;

            for (let spec of this.SpecList) {
              for (let row of this.ShowSpecList) {
                if (spec.spec_name == row.spec.spec_name) {
                  spec.image = row.spec.image;
                }
              }
              for (let s of spec.size) {
                for (let row of this.ShowSpecList) {
                  if (spec.spec_name == row.spec.spec_name && s.size_name == row.size.size_name) {
                    s.size_coin = row.size.size_coin ? row.size.size_coin : 0;
                    s.size_price = row.size.size_price ? row.size.size_price : 0;

                    s.status = row.size.status ? 1 : 0;
                  }
                }
              }

            }
            console.log(this.SpecList);
            //计算上传数量
            let spec_count = this.SpecList.length;
            let size_count = 0;
            let spec_upload = 0;
            let size_upload = 0;
            for (let spec of this.SpecList) {
              for (let s of spec.size) {
                size_count++;
              }
            }





            //for (let spec of this.SpecList) {
            if (this.SpecList.length > 0) {

              let saveSpec = (i) => {

                if (i < this.SpecList.length) {
                  let spec = this.SpecList[i];


                  //创建规格
                  let _spec = {
                    size: spec.size,
                    goods_id: this.bodyForm.id,
                    spec_name: spec.spec_name,
                    image: spec.image,
                    status: 1
                  };
                  ((_spec) => {
                    let data = {
                      goods_id: this.bodyForm.id,
                      spec_name: _spec.spec_name,
                      image: _spec.image,
                      status: 1
                    }
                    this.$http.post("/api/good_createSpec", data).then(res => {
                      spec_upload++;
                      if (_spec.size.length > 0) {

                        let saveSize = (m) => {

                          if (m < _spec.size.length) {
                            // for (let s of _spec.size) {
                            let s = _spec.size[m];
                            //创建size
                            let size = {
                              goods_id: this.bodyForm.id,
                              size_name: s.size_name,
                              size_coin: s.size_coin,
                              size_price: s.size_price,
                              goods_spec_id: res.data.id,
                              weight: s.weight ? s.weight : 0,
                              stock: s.stock ? s.stock : 0,
                              code: s.code ? s.code : '',
                              status: 1
                            }
                            this.$http.post("/api/good_createSize", size).then(res => {
                              size_upload++;
                              console.log(spec_upload, spec_count, size_upload, size_count)
                              if (spec_upload == spec_count && size_upload == size_count) {


                              }

                              saveSize(m + 1); //按顺序保存

                            })

                          }
                        }
                        saveSize(0);
                      }


                      saveSpec(i + 1); //按顺序保存

                    })
                  })(_spec);
                }
              }
              saveSpec(0);
            }

            this.$message({
              message: '保存成功',
              type: 'success'
            });
            setTimeout(() => {
              window.history.back();
            }, 2000)

          })
        } else { //更新
          good.id = this.bodyForm.id;
          this.$http.post("/api/good_createItem", good).then(res => {
            if (res.data.status == "fail") {
              this.$message({
                message: res.data.msg,
                type: 'error'
              });
              return
            }
            for (let spec of this.SpecList) {
              for (let row of this.ShowSpecList) {
                if (spec.spec_name == row.spec.spec_name) {
                  spec.image = row.spec.image;
                }
              }
              for (let s of spec.size) {
                for (let row of this.ShowSpecList) {
                  if (spec.spec_name == row.spec.spec_name && s.size_name == row.size.size_name) {
                    s.size_coin = row.size.size_coin ? row.size.size_coin : 0;
                    s.size_price = row.size.size_price ? row.size.size_price : 0;
                    s.status = row.size.status ? 1 : 0;
                  }
                }
              }

            }
            console.log(this.SpecList);
            //计算上传数量
            let spec_count = this.SpecList.length;
            let size_count = 0;
            let spec_upload = 0;
            let size_upload = 0;
            for (let spec of this.SpecList) {
              for (let s of spec.size) {
                if (s.id <= 0) {
                  size_count++;
                }

              }
            }



            if (this.SpecList.length > 0) {

              let saveSpec = (i) => {
                if (i < this.SpecList.length) {
                  let spec = this.SpecList[i];

                  //创建规格
                  let _spec = {
                    size: spec.size,
                    goods_id: this.bodyForm.id,
                    spec_name: spec.spec_name,
                    image: spec.image,
                    status: 1,
                    id: spec.id ? spec.id : 0
                  };


                  ((_spec) => {
                    if (_spec.id > 0) {
                      spec_upload++;
                      this.$http.post("/api/good_createSpec", _spec).then(res => {})
                      //递归保存尺寸，按顺序保存
                      if (_spec.size.length > 0) {


                        let saveSize = (m) => {

                          if (m < _spec.size.length) {
                            //for (let s of _spec.size) {
                            //创建size
                            let s = _spec.size[m];
                            let size = {
                              id: s.id,
                              goods_id: this.bodyForm.id,
                              size_name: s.size_name,
                              size_coin: s.size_coin,
                              size_price: s.size_price,
                              goods_spec_id: _spec.id,
                              weight: s.weight ? s.weight : 0,
                              stock: s.stock ? s.stock : 0,
                              code: s.code,
                              status: 1
                            }


                            if (!size.id) {

                              this.$http.post("/api/good_createSize", size).then(res => {
                                size_upload++;
                                console.log(spec_upload, spec_count, size_upload, size_count)
                                if (spec_upload == spec_count && size_upload == size_count) {


                                }

                                saveSize(m + 1)

                              })
                            } else {
                              this.$http.post("/api/good_createSize", size).then(res => {
                                size_upload++;
                                console.log(spec_upload, spec_count, size_upload, size_count)
                                if (spec_upload == spec_count && size_upload == size_count) {


                                }
                                saveSize(m + 1)

                              })

                            }

                          }
                        }

                        saveSize(0)
                      }
                      saveSpec(i + 1);
                    } else {
                      let data = {
                        goods_id: this.bodyForm.id,
                        spec_name: _spec.spec_name,
                        image: _spec.image,
                        status: 1
                      }

                      this.$http.post("/api/good_createSpec", data).then(res => {
                        if (_spec.size.length > 0) {

                          let saveSize = (m) => {

                            if (m < _spec.size.length) {
                              // for (let s of _spec.size) {
                              let s = _spec.size[m];
                              //创建size
                              let size = {
                                goods_id: this.bodyForm.id,
                                size_name: s.size_name,
                                size_coin: s.size_coin,
                                size_price: s.size_price,
                                goods_spec_id: res.data.id,
                                weight: s.weight ? s.weight : 0,
                                stock: s.stock ? s.stock : 0,
                                code: s.code,
                                status: 1
                              }

                              this.$http.post("/api/good_createSize", size).then(res => {
                                size_upload++;
                                console.log(spec_upload, spec_count, size_upload, size_count)
                                if (spec_upload == spec_count && size_upload == size_count) {


                                }

                                saveSize(m + 1); //按顺序保存

                              })

                            }
                          }
                          saveSize(0);
                        }


                        saveSpec(i + 1);

                      })
                    }

                  })(_spec);



                }
              }

              saveSpec(0);
            }

            this.$message({
              message: '保存成功',
              type: 'success'
            });
            setTimeout(() => {


              window.history.back();
            }, 2000)

          })

        }

      }

    }
  }
</script>
<style scoped>
  .images-box {
    position: relative;
    display: inline-block;
    margin: 0px 25px 10px 0px;
  }

  .images-del {
    position: absolute;
    right: -12px;
    top: -12px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    padding: 5px;
    font-size: 13px;
    color: #FF4949;
    /*border: 1px solid #FF4949;*/
    border-radius: 50%;
    cursor: pointer;
  }

  .images-del2 {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 34px;
    height: 34px;
    line-height: 14px;
    text-align: center;
    padding: 5px;
    font-size: 30px;
    color: #FF4949;
    /*border: 1px solid #FF4949;*/
    border-radius: 50%;
    cursor: pointer;
  }

  .el-card {
    overflow: initial;
  }

  .imgright {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 30px;
    opacity: 0.1;
  }

  .imgright2 {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 100px;
    transform: rotateZ(90deg);

  }

  .imgright:hover {
    opacity: 1;
  }

  .imgleft {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 30px;
    opacity: 0.1;
    transform: rotateZ(180deg);
  }

  .imgleft2 {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 50px;

    transform: rotateZ(-90deg);
  }

  .imgleft:hover {
    opacity: 1;
  }

  .spectable {
    background: #fff;
  }

  .spectable td {
    background: #fff;
    padding: 4px;
    text-align: center;
    border-bottom: 1px solid #f3f4f4;
    border-right: 1px solid #f3f4f4;
  }

  .spectable th {
    background: #fbfcfd;
    padding: 6px;
    border-bottom: 1px solid #f3f4f4;
    text-align: center;
  }

  .tdtxt {
    border: 1px solid #ccc;
    padding: 6px;
    width: 80%;
    min-width: 30px;
    border-radius: 4px;
    outline: none;
  }

  .imagecover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }

  .imagecover:hover {
    opacity: 1;
  }
</style>
